<template>
    <div>
        <HeaderXS></HeaderXS>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">

                <h1>Gutscheine</h1>
                <h2>Brillante Geschenkidee</h2>
            </div>
            <div class="col-12">
                <p class="demi">
                    Die Geschenkgutscheine der Waschprofis sind immer eine saubere Sache. Mit wenigen Klicks bestellt, erhalten Sie ansprechend gestaltete Gutscheine per Post.
                </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">

                <div class="col-12 text-center">
                    <h3>Gutschein</h3>
                </div>

                <div class="col-12">
                    <form>
                        <label class="col-form-label">Betrag:</label>
                        <div class="form-group" :class="{ 'form-group--error': $v.cart.couponValue.$error }">
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.cart.couponValue.$error }" id="coupon-value" v-model.trim="$v.cart.couponValue.$model"  @blur="$v.cart.couponValue.$touch()" value="" placeholder="Betrag eingeben">
                            <div class="error" v-if="!$v.cart.couponValue.required">Bitte Betrag angeben</div>
                            <div class="error" v-if="!$v.cart.couponValue.decimal">Bitte gültigen Betrag angeben</div>

                        </div>
                        <div class="grey-bg">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="cart.deliveryType" name="deliveryTypeOptions" id="deliveryType1" value="postal">
                                <label class="form-check-label" for="deliveryType1">Liefern</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="cart.deliveryType" name="deliveryTypeOptions" id="deliveryType2" value="pickup">
                                <label class="form-check-label" for="deliveryType2">Selbstabholer</label>
                            </div>
                        </div>

                        <template v-if="cart.deliveryType == 'postal'">
                            <label class="col-form-label">Lieferadresse:</label>

                            <div class="form-group">
                                <select class="form-control" id="title" v-model.trim="shipping_data.title">
                                    <option selected value="">Anrede</option>
                                    <option selected value="Frau">Frau</option>
                                    <option selected value="Herr">Herr</option>
                                </select>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.shipping_data.lastname.$error }">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.lastname.$error }" id="lastname" placeholder="Nachname" v-model.trim="$v.shipping_data.lastname.$model"  @blur="$v.shipping_data.lastname.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.lastname.required">Bitte Nachnamen angeben</div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.shipping_data.firstname.$error }">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.firstname.$error }" id="firstname" placeholder="Vorname" v-model.trim="$v.shipping_data.firstname.$model"  @blur="$v.shipping_data.firstname.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.firstname.required">Bitte Vornamen angeben</div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.shipping_data.street.$error }">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.street.$error }" id="street" placeholder="Straße/Nr." v-model.trim="$v.shipping_data.street.$model"  @blur="$v.shipping_data.street.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.street.required">Bitte Straße angeben</div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.shipping_data.zipcode.$error }">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.zipcode.$error }" id="zipcode" placeholder="PLZ" v-model.trim="$v.shipping_data.zipcode.$model"  @blur="$v.shipping_data.zipcode.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.zipcode.required">Bitte Postleitzahl angeben</div>
                                    <div class="error" v-if="!$v.shipping_data.zipcode.numeric">Bitte gültige Postleitzahl angeben</div>
                                    <div class="error" v-if="!$v.shipping_data.zipcode.minLength">Bitte gültige Postleitzahl angeben</div>
                                    <div class="error" v-if="!$v.shipping_data.zipcode.maxLength">Bitte gültige Postleitzahl angeben</div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.shipping_data.city.$error }">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.city.$error }" id="city" placeholder="Ort" v-model.trim="$v.shipping_data.city.$model"  @blur="$v.shipping_data.city.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.city.required">Bitte Stadt angeben</div>
                            </div>
                            <div class="input-group form-group" :class="{ 'form-group--error': $v.shipping_data.phone.$error }">

                                <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">+49</span>
                                    </div>
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.phone.$error }" id="phone" placeholder="Telefon" v-model.trim="$v.shipping_data.phone.$model"  @blur="$v.shipping_data.phone.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.phone.required">Bitte Telefonnummer angeben</div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.shipping_data.mail.$error }">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.shipping_data.mail.$error }" id="mail" placeholder="E-Mail" v-model.trim="$v.shipping_data.mail.$model"  @blur="$v.shipping_data.mail.$touch()">
                                    <div class="error" v-if="!$v.shipping_data.mail.required">Bitte E-Mail Adresse angeben</div>
                                    <div class="error" v-if="!$v.shipping_data.mail.email">Bitte eine gültige E-Mail Adresse angeben</div>
                            </div>
                        </template>

                        <template v-if="cart.deliveryType == 'pickup'">
                            <p>Nach dem Bezahlen können Sie Ihren Gutschein direkt bei uns:<br><br>
                                Autohaus Israel GmbH & Co. KG<br>
                                Dohnaer Straße 310<br>
                                01257 Dresden<br><br>
                                Montag bis Freitag 06:30–19:00 Uhr und<br> Samstags 09:00–13:00 Uhr abholen.
                            </p>
                        </template>

                        <div class="form-group">
                            <a @click="addToCart()" class="button invert">Kaufen</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <FooterXS></FooterXS>
    </div>
</template>
<script>

    import { required, requiredIf, email, numeric, decimal, minLength, maxLength } from 'vuelidate/lib/validators'

    import HeaderXS from "@/components/HeaderXS.vue";
    import FooterXS from "@/components/FooterXS.vue";

    export default {
        name: 'Coupons',
        components: {
            HeaderXS,
            FooterXS,
        },
        data() {
            return {
                shipping_data: this.$store.state.user_shipping_data.user_shipping_data,
                cart: this.get_cart()
            }
        },
        watch: {
            phoneNumber(newVal){
                if(newVal.startsWith("0")){
                    this.shipping_data.phone = newVal.slice(1);
                }else if(newVal.startsWith("+49")){
                    this.shipping_data.phone = newVal.slice(3);
                }
            }
        },
        methods:{
            get_cart() {
              if(this.$store.state.cart.length < 1 || this.$store.state.cart.product !== 'coupon'){
                  this.$store.commit({
                      type: 'addToCart',
                      product: 'coupon',
                      couponValue: '',
                      deliveryType: 'postal',
                  })
              }
              return this.$store.state.cart
            },
            addToCart() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {

                    if(this.cart.deliveryType === 'postal'){
                        this.$store.commit({
                            type: 'addToUserShippingData',
                            user_shipping_data: this.shipping_data,
                        })
                    }
                    this.$store.commit({
                        type: 'addToCart',
                        product: 'coupon',
                        couponValue:  this.cart.couponValue,
                        deliveryType:  this.cart.deliveryType,
                    })
                    this.$router.push({name: 'BookingForm'})
                }
            }
        },
        computed: {
          phoneNumber() {
            return this.shipping_data.phone
          },
        },
        validations () {
            return {
                cart: {
                    couponValue: {
                        required: required,
                        decimal: decimal
                    },
                },
                shipping_data: {
                    lastname: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        })
                    },
                    firstname: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        })
                    },
                    street: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        })
                    },
                    zipcode: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        }),
                        numeric,
                        minLength: minLength(5),
                        maxLength: maxLength(5),
                    },
                    city: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        })
                    },
                    phone: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        })
                    },
                    mail: {
                        required: requiredIf(() => {
                            if(this.cart.deliveryType === 'postal'){
                                return true;
                            }
                        }),
                        email
                    },
                },
            }
        }
    }
</script>
<style lang="scss" scoped>

    h1{
        font-weight:400;
        font-size: 18px;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    h2{
        font-family: 'futura-pt-bold';
        font-size: 28px;
        letter-spacing: .7px;
        margin-bottom: 50px;
    }

    h3{
        font-size:20px;
        font-family: 'futura-pt-bold';
        letter-spacing: 0.5px;
        margin-top:55px;
    }

    .form-check-label,
    p{
        font-size: 17px;
        letter-spacing: 0.55px;
    }

    .col-form-label{
        font-weight: 600;
        font-size: 21px;
        letter-spacing: 0.53px;
    }
    .error {
        font-size: 0.85rem;
        line-height: 1;
        display: none;
    }

    form{

        .grey-bg{
            padding: 35px 45px;

            .form-check{

                label{
                    line-height: 30px;
                }
            }
        }

        .button{
            text-align: center;
            width:100%;
        }

        .form-control,
        .form-group{
            background-color: #edeef0;
        }

        .form-control{
            height: 65px;
            border: none;
            color: #000;

            &.is-invalid{
                border: thin solid #dc3545;
            }
        }

        .form-group--error .error {
            margin-top: 10px;
            display: block;
            color: #dc3545;
        }
        .error {
            font-size: 0.85rem;
            line-height: 1;
            display: none;
        }

        .form-check{
            margin-right: 75px;

            label{
                font-size: 17px;
                letter-spacing: 0.42px;
                line-height: 40px;
            }
        }
    }


</styles>